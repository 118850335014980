export interface DocTypeMetadatas {
    documentationTypeId: number;
    metadataId: number;
    systemName: string;
    categoryId: number;
    id: number;
    optionValues?: any;
}

export enum DocTypeMetadatasCategory {
    Subtipo = 1
  }
